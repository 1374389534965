import logo from "./logo.webp"

const clientData = {
    client_entity: 24,
    attorney_firm: 'Warren & Migliaccio',
    attorney_name: 'Christopher Migliaccio',
    attorney_number: '(888) 550-6702',
    attorney_email: 'christopher@wmtxlaw.com',
    client_video_source: 'https://erc-videos.s3.amazonaws.com/ertc_explainer_-_warren_%26_migliaccio+(720p).mp4',
    mainColor: '#D3BC90',
    secondaryColor: '#32487A',
    siteLink: 'http://www.wmtxlaw.com',
    logo
}

export default clientData